import React from 'react'
import AppLayout from '@/layouts/AppLayout'

import img404 from "@/assets/404.png"
import { useTranslation } from 'react-i18next'
import { Button } from '@nextui-org/react'
import { Link } from '@inertiajs/react'

const Page404 = () => {
    const { t } = useTranslation('common')
    return (
        <AppLayout>
            <div className='flex flex-col text-center gap-4 items-center justify-center my-28 p-2'>
                <img src={img404} />
                <h1 className='text-xl md:text-5xl font-bold'>{t('404-title')}</h1>
                <p className='text-lg lg:text-3xl'>{t('404-subtitle')}</p>
                <Button className='bg-primary text-white py-2 px-4 rounded-xl '>
                    <Link href='/' >
                        {t('back-home')}
                    </Link>
                </Button>
            </div>
        </AppLayout>
    )
}

export default Page404